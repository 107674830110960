import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gallery from '@browniebroke/gatsby-image-gallery';
import { graphql } from 'gatsby';
export const query = graphql`
  query kidsStories {
    images: allFile(
      filter: { relativeDirectory: { eq: "kids-stories" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(layout: CONSTRAINED, width: 400)
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
          name
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Kids Stories",
  "date": "2020-12-09T00:00:00.000Z"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery images={props.data.images.edges.map(({
      node
    }) => ({
      ...node.childImageSharp,
      caption: node.name,
      thumbAlt: node.name
    })).slice(0, 4)} mdxType="Gallery" />
    <p>{`Story 1`}</p>
    <Gallery images={props.data.images.edges.map(({
      node
    }) => ({
      ...node.childImageSharp,
      ...node.childImageSharp,
      caption: node.name,
      thumbAlt: node.name
    })).slice(4, 8)} mdxType="Gallery" />
    <p>{`Story 2`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      